import { ClientService } from "../client-service/client-service";
import { GlobalAccessService } from "../global-access-service/global-access-service";
import { StorageService } from "../models/storage-service";
import { Themes } from "./themes";

export class ThemeService {

    private static instance: ThemeService;

    private storageKey = "theme";
    private currentTheme!: Themes;

    private themeClasses = new Map([
        [Themes.light, ''],
        [Themes.dark, 'dark-theme'],
        [Themes.aqua, 'aqua-theme'],
        [Themes.jungle, 'jungle-theme'],
        [Themes.royal, 'royal-theme'],
        [Themes.pumpkin, 'pumpkin-theme'],
        [Themes.festive, 'festive-theme']
    ]);

    constructor(
        private storageService: StorageService,
        private clientService: ClientService
    ) {
        // NOTE: this is a hacky way to enforce singletons!!
        if (ThemeService.instance) {
            return ThemeService.instance;
        }

        this.initializeTheme().then(() => {
            this.setTheme(this.currentTheme);
        });

        ThemeService.instance = this;

        this.initializeGlobalHandlers();
    }

    async toggleLightDarkTheme(): Promise<void> {
        await this.initializeTheme();

        switch (this.currentTheme) {
            case Themes.light:
                this.setTheme(Themes.dark);
                break;
            case Themes.dark:
                this.setTheme(Themes.light);
                break;
            default:
                this.setTheme(this.getSystemTheme());
                break;
        }
    }

    getThemes(): string[] {
        if (this.clientService.getConfig().themes.extendedThemesEnabled) {
            return Object.values(Themes);
        }
        return [
            Themes.light,
            Themes.dark
        ];
    }

    getCurrentTheme(): Promise<Themes> {
        return this.storageService.get(this.storageKey).then(({theme}: any) => theme);
    }

    setTheme(theme: Themes): void {
        if (!this.clientService.getConfig().themes.extendedThemesEnabled && theme !== Themes.light && theme !== Themes.dark) {
            theme = this.getSystemTheme();
        }

        this.currentTheme = theme;
        
        for (const [aTheme, tClass] of this.themeClasses) {
            if (!tClass) {
                continue;
            }

            if (aTheme === theme) {
                document.body.classList.add(tClass);
            } else {
                document.body.classList.remove(tClass);
            }
        }

        this.storageService.set(this.storageKey, { theme: this.currentTheme });
    }

    private async initializeTheme(): Promise<void> {
        if (!this.currentTheme) {
            const existing: any = await this.storageService.get(this.storageKey);
            if (existing) {
                this.currentTheme = existing.theme;
            } else {
                // get the system default
                this.currentTheme = this.getSystemTheme();
            }
        }
    }

    private getSystemTheme(): Themes {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            ? Themes.dark
            : Themes.light;
    }

    private initializeGlobalHandlers(): void {
        GlobalAccessService.getInstance().registerGlobalHandlers({
            themeService: {
                setTheme: this.setTheme.bind(this)
            }
        });
    }
}