export enum Client {
    webBrowser = "webBrowser",
    androidApp = "androidApp",
    androidAppLocal = "androidAppLocal",
    iosApp = "iosApp",
    iosAppLocal = "iosAppLocal",
    webBrowserBook = "webBrowserBook",
    localhost = "localhost",
    steamApp = "steamApp"
};

export enum AdProviders {
    infolinks = "infolinks",
    bookOfTheMonth = "book-of-the-month",
    authorBook = "author-book",
    google = "google"
};

export interface ClientConfig {
    body: {
        appBodyScrollDisabled: boolean;
        fontOverride?: string;
        fullScreenEnabled: boolean;
        gameCompleteAdVisible: boolean;
        styledButton: {
            pointerUpCssForce: boolean;
        };
        navMenuSubmenuEnabled: boolean;
    };
    footer: {
        clientText: string;
        googleAdVisiblePercent: number;
    }
    gameBoard: {
        thickBorders: boolean;
        boldCellValues: boolean;
        defaultHexHeight: string;
        hexFontMultiplier: number;
        colorInfoMessage: string;
        zoomMessage: string;
        showAppMessage: boolean;
        showCellConstraintsHover: boolean;
        puzzleCompletionModal: {
            enabled: boolean;
        };
        generatorPatternCreate: {
            enabled: boolean;
            experimentalEnabled: boolean;
        };
        hexCell: {
            rightClickStrategy: 'contextmenu' | 'wait';
            numPencilsVisible: number;
        };
    };
    tutorial: {
        type: "interactive" | "popup"
    };
    gameAchievements: {
        emitStrategy: 'none' | 'emit-in-javascript';
        displayToast: boolean;
        displayAchievementsView: boolean;
    };
    urls: {
        alterScheme: boolean;
    };
    ads: {
        requestDisplayPermission: {
            enabled: boolean;
        };
        adProviders: {provider: AdProviders; percentage: number}[];
        viewPercentage: number;
    };
    analytics: {
        logEvents: boolean;
    };
    gameComplete: {
        mockStreak: boolean;
    };
    clientDispatcher: {
        readyResolveStrategy: 'immediate' | 'android' | 'ios';
    };
    controls: {
        window: boolean;
    };
    dailyPuzzles: {
        future: boolean;   
    };
    themes: {
        extendedThemesEnabled: boolean;
    };
    share: {
        navigatorEnabled: boolean;
    }
};